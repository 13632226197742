body {
  min-width: 300px;
}
.aside {
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #333;
  padding: 20px;
  color: #fff;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  margin-left: 30%;
}

.content-title {
  margin: 50px 0 80px;
}

.content-body {
  margin: 10%;
}

.profile {
  text-align: center;
}

.name {
  font-size: 1em;
  font-weight: bold;
  margin: 20px 0;
  color: #fff;
}

.bio {
  font-size: 0.8em;
}

.avatar {
  vertical-align: middle;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.social {
  justify-content: center;
}

.language a {
  color: #fff;
}

.language .active {
  font-weight: bold;
  font-size: 1.1em;
  text-decoration: underline;
}

.strong {
  font-weight: bold;
}

.card img {
  width: 100%;
  object-fit: cover;
  object-position: top;
  height: 200px;
}

.portfolio {
  margin: 60px auto;
}

.portfolio .col-img-small {
  margin: 15px 0;
}

.portfolio .col-img-small img {
  width: 100%;
  object-fit: cover;
  height: 200px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .aside {
    position: relative;
    width: 100%;
    height: auto;
    display: block;
    padding: 50px;
  }

  .content {
    margin: 0;
  }
}
